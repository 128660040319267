.projets {
    margin: 4rem 6rem;
}

.projets h1 {
    font-size: 3rem;
}


.projectcard {
    margin-top: 3rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.p-card {
    width: 49%;
    text-align: start;
    box-shadow: 0 5px 25px 2px rgba(0, 0, 0, 0.11);
    border-radius: 7px;
    cursor: pointer;
    padding: 1rem .8rem;
    margin-bottom: 1rem; 
}

.project-image {
    height: 300px;
    overflow: hidden;
    border-radius: 7px;
}

.project-image img {
    width: 100%;
    height: 100%;
    transition: 0.3s ease-in-out;
}

.project-image:hover img {
    transform: scale(1.3);
}

.p-card h4 {
    font-size: 1.3rem;
    padding: .9rem 0 .2rem 0;
}

@media screen and (max-width: 850px) {
    .projets {
        margin: 4rem 2rem;
    }

    .projectcard {
        flex-direction: column;
    }

    .p-card {
        width: 100%;
        margin-bottom: 1.5rem;
    }
}