.destination {
    margin: 4rem 6rem;
}

.destination h1 {
    font-size: 4rem;
}

.first-description {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 6rem;
}

.second-description {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    margin-top: 6rem;
}

.text-description {
    width: 44%;
    text-align: start;
    font-size: 1.1rem;
}

.text-description h2 {
    padding-bottom: 1rem;
}

.description-image {
    position: relative;
    width: 50%;
    display: flex;
    justify-content: space-between;
    z-index: -99;
}

.description-image img {
    width: 49%;
    height: 350px;
    object-fit: cover;
    border-radius: 6px;
    box-shadow: -1px 1px 62px -30px rgba(0, 0, 0, 0.19);

}

.description-image img:nth-child(2) {
    position: absolute;
    top: -10%;
    right: 0;
}



@media screen and (max-width: 850px) {
    .destination {
        margin: 4rem 2rem;
    }

    .first-description {
        display: flex;
        flex-direction: column-reverse;
        margin-top: 4rem;
    }

    .second-description {
        display: flex;
        flex-direction: column-reverse;
        margin-top: 4rem;
    }

    .text-description {
        width: 100%;
     
    }

    .description-image{
        width: 100%;
        margin: 1.8rem 0;
    }

    .description-image img{
        height: 250px;
    }
}