.tradition {
    margin: 4rem 6rem;
}

.tradition h1 {
    font-size: 3rem;
}

@media screen and (max-width: 850px) {
    .tradition {
        margin: 4rem 2rem;
    }
}