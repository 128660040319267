@import url('https://fonts.googleapis.com/css2?family=Bungee+Spice&family=Manrope:wght@400;500;600;700;800&display=swap');
@import "~react-image-gallery/styles/css/image-gallery.css";
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  text-align: center;
  font-family: 'Manrope', sans-serif;
}

.privacy-container {
  margin: 4rem 6rem;
  padding-top: 5rem ;
  text-align: start;
 
}

.privacy-container h2{
  padding-bottom: 2rem;
}

.acctualite   {
    margin: 4rem 6rem;
    padding-top: 2rem;
}
.acctualite h2{
  padding-bottom: 2rem;
}
.acctualite li {
  padding: .4rem;
  list-style-type: none;
}


@media screen and (max-width: 850px) {
  .acctualite {
      margin: 4rem 2rem;
      padding-top: 1rem;
  }

  .privacy-container {
    margin: 4rem 2rem;
  }
}



/* css pour donation button  */
/* .ribbon {
  width: 60px;
  font-size: 14px;
  padding: 4px;
  position: absolute;
  right: 0px;
  top: 20px;
  text-align: center;
  border-radius: 25px;
  transform: rotate(35deg);
  background-color: #ff9800;
  color: white;
  z-index: 9999999;
  
} */