.valeur {
    margin: 4rem 6rem;
    color: #2a2a2a;
}

.valeur h1 {
    font-size: 3rem;
}

.valeurcard {
    margin-top: 3rem;
    display: flex;
    justify-content: space-between;
}

.va-card {
    width: 32%;
    text-align: start;
    box-shadow: 0 5px 25px 2px rgba(0, 0, 0, 0.11);
    border-radius: 7px;
    cursor: pointer;
    padding: 1rem .8rem;
    text-align: center;
}

.valeur-image {
    height: 200px;
    overflow: hidden;
    border-radius: 4px;
}

.valeur-image img {
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 10px;
    width: 100%;
    height: 100%;
    transition: 0.3s ease-in-out;
}

.valeur-image:hover img {
    transform: scale(1.3);
}

.va-card h4 {
    font-size: 1.3rem;
    padding: .9rem 0 .2rem 0;
}

.va-card h6 {
    font-size: .8rem;
    padding: .9rem 0 .2rem 0;
    text-align: end;
    color: #ddd;
}

.va-card p {
    color: grey;
}

@media screen and (max-width: 850px) {
    .valeur {
        margin: 4rem 2rem;
    }

    .valeurcard {
        flex-direction: column;
        align-items: center;
    }

    .va-card {
        width: 80%;
        margin-bottom: 1.5rem;
        
    }

}