.hero {
    width: 100%;
    height: 96vh;
    position: relative;
}

img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.hero-text {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}

.hero-text h1 {
    font-size: 3.5rem;
    font-weight: 800;
    background: white;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.hero-text p {
    font-size: 1.5rem;
    font-weight: 800;
    color: white;
    padding: 0.5rem 0 2rem 0;
}

.hero-text .show {
    text-decoration: none;
    background: #fff;
    padding: 1rem 2rem;
    border-radius: 6px;
    font-size: 1.1rem;
    font-weight: bold;
    letter-spacing: 2px;
    color: #222;
    border: none;
}

.hero-mid {
    width: 100%;
    height: 60vh;
    position: relative;
}

.hero-mid h1 {
    padding-top: 4rem !important;
}

.bottom-arrow {
    border-bottom: 3px solid #6A0136;
}

.bottom-arrow:after {
    content:'';
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 0;
    height: 0;
    border-top: 25px solid #6A0136;
    border-left: 50px solid transparent;
    border-right: 50px solid transparent;
}

.show:hover {
    background-color: #01959a;
    color: #fff;
}

@media screen and (max-width: 555px) {
    .hero-text h1 {
        padding: 10px 20px;
    }

    .hero-text p {
        font-size: 1.1rem;
        padding: 0 0 2rem 0;
    }

    .hero-text .show {
        padding: .6rem 1.1rem;
        border-radius: 6px;
        font-size: 1rem;
    }
}