.navbarItems {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.25);
    width: 95%;
    height: 80px;
    border-radius: 13px;
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translate(-50%);
    z-index: 9999;
    background-color: #fff;
}

.navbar-logo {
    color: #222;
    font-size: 2rem;
    cursor: pointer;
    text-decoration: none;
}

.navbar-menu {
    display: grid;
    grid-template-columns: repeat(6, auto);
    gap: 5px;
    list-style: none;
    align-items: center;
}

.nav-links {
    text-decoration: none;
    color: #222;
    font-size: 1.2rem;
    font-weight: 600;
    padding: 0.7rem 1rem;
    white-space: nowrap;
}

.nav-links i {
    padding-right: 10px;
}

.nav-links:hover {
    background-color: #01959a;
    color: white;
    border-radius: 4px;
    transition: all 0.2s ease-in-out;
}

.menu-icons {
    display: none;
}

@media screen and (max-width: 1055px) {
    .navbarItems {
        z-index: 99;
    }

    .navbar-menu {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        background-color: #fff;
        width: 100%;
        height: auto;
        position: absolute;
        top: 0;
        left: -110%;
        opacity: 0;
        align-items: stretch;
        padding: 80px 0 30px 0;
        margin: 0;
        transition: 0.3s ease-in-out;
        border-radius: 13px;
        gap: 10px;
    }

    .navbar-menu.active {
        left: 0;
        opacity: 1;
        z-index: -1;
        transition: 0.3s ease-in-out;
    }

    .nav-links {
        display: block;
        width: 100%;
        font-size: 1.2rem;
        padding: 2rem 0;
    }

    .nav-links:hover {
        background-color: #01959a;
        transition: none;

    }

    .menu-icons {
        display: block;
        cursor: pointer;
    }

    .menu-icons i{
        font-size: 1.2rem;
        color: #222;
    }
}