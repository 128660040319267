.assemble {
    margin: 4rem 6rem;
}

.assemble h1 {
    font-size: 3rem;
}

.first-assemble {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 6rem;
}

.second-assemble {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    margin-top: 6rem;
}

.text-assemble {
    width: 44%;
    text-align: start;
    font-size: 1.1rem;
}

.text-assemble h2 {
    padding-bottom: 1rem;
}

.assemble-image {
    position: relative;
    width: 50%;
    display: flex;
    justify-content: space-between;
    z-index: -99;
}

.assemble-image img {
    width: 33%;
    height: 350px;
    object-fit: cover;
    border-radius: 6px;
    box-shadow: -1px 1px 62px -30px rgba(0, 0, 0, 0.19);

}

@media screen and (max-width: 850px) {
    .assemble {
        margin: 4rem 2rem;
    }

    .first-assemble {
        display: flex;
        flex-direction: column-reverse;
        margin-top: 4rem;
    }

    .second-assemble {
        display: flex;
        flex-direction: column-reverse;
        margin-top: 4rem;
    }

    .text-assemble {
        width: 100%;

    }

    .assemble-image {
        width: 100%;
        margin: 1.8rem 0;
    }

    .assemble-image img {
        height: 250px;
    }
}